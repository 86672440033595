import React, { Component } from 'react'

export class WhyYastabot extends Component {
  render() {
    return (
      <div id="why-yastabot" className="text-center">
        <div className="container">
          <div className="row">
            <h2>{this.props.data ? this.props.data.title : "Loading"}</h2>
          </div>
          <div className="row">
            <h4>{this.props.data ? this.props.data.text1 : "Loading"}</h4>
          </div>
          <div className="row img-container">
            <img src={this.props.data ? this.props.data.usVsThem : "Loading"}></img>
          </div>
        </div>
      </div>
    )
  }
}

export default WhyYastabot

