import React, { Component } from "react";

export class Footer extends Component {
    render() {
        return (
            <div id="footer" className="text-center">
        <div className="container">
          <div className="row">
            <h3>{this.props.data ? this.props.data.text : "Loading"}</h3>
          </div>
          <div className="row">
            <a href="https://t.me/yastabot?start=website" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-call-to-action btn-lg"> <i className="fa fa-send"></i> Get started</button>
            </a>
          </div>
          <div id="">
            <div className="container text-left">
              <div className="row">
                <p>
                  {this.props.data ? this.props.data.footer1 : "Loading"}
                </p>
              </div>
              <div className="row links">
                <p> &copy; 2021 Yastabot </p>
                <p> <a href="https://t.me/yastabot?start=website">Telegram bot</a> </p>
                <p> <a href="https://m.me/yastabot">Messenger bot</a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        );
    }
}

export default Footer;