import React, { Component } from "react";
import Typewriter from 'typewriter-effect';
 

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div id="intro">
          <div className="container">
            <div className="row text-center">
                <h2>{this.props.data ? this.props.data.text1 : "Loading"}</h2>
            </div>
            <div className="row">
              <div className="type-writer-container">
                <div className="row intro-text type-writer" style={{whiteSpace: "pre-wrap"}}>
                <Typewriter 
                    options={{
                      strings: ["/remind me in 5m to eat", "/remindme at 2 pm to do my homework", "/remindme on wednesday to pickup the kids from school", "/remindme every jan 5th that today is my birthday!", "/remindme every weekday at 9 am to open up the shop"],
                      autoStart: true,
                      loop: true,
                      skipAddStyles: true,
                      deleteSpeed: 20,
                      pauseFor: 1000
                    }}
                />
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="container">
                <a href="https://t.me/yastabot?start=website" target="_blank" rel="noopener noreferrer">
                  <button className="btn btn-call-to-action btn-lg"><i className="fab fa-telegram"></i> Get started</button>
                </a>
                <a href="https://m.me/yastabot?start=website" target="_blank" rel="noopener noreferrer">
                  <button className="btn btn-call-to-action btn-lg"><i className="fab fa-facebook-messenger"></i> Get started</button>
                </a>
              </div>
            </div>
            <div className="row text-center">
              <a href={this.props.data ? this.props.data.button2 : "Loading"} target="_blank" rel="noopener noreferrer">
                <button className="btn btn-custom2">Read our<br/>Medium Article</button>
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }  
}

export default Header;